import React from "react";
import './topbottombar.css';
import logo from '../../assets/viewie-logo-tekst-wit.svg'


function Bottombar(props) {
  return (
    <div className="bottomBar">
        <div className="inner-bottom-bar">
            <div>Viewie Media B.V. © 2014-2022</div>
            <img src={logo} alt="" />
            <div>MakelaarsTV is een product van Viewie Media B.V.</div>
        </div>
    </div>
  );
}
export default Bottombar;
